import KatalMetricsPublisher from '@katal/metrics/lib/KatalMetricsPublisher';
import * as aws4 from 'aws4';
import { AuthSession } from '@awsscm/awsscm-auth-manager/auth/type';

const SERVICE_NAME = 'ocean-wave';

export function fetchWithWebIdentity(input: RequestInfo, metricsPublisher: KatalMetricsPublisher, token: string, init?: RequestInit) {
  if (token) {
    if (!init) {
      init = {};
    }
    const headers =  {
      'Authorization': token,
      ...init.headers
    };
    init.headers = headers;
  }
  return fetchAPI(input, init, metricsPublisher);
}

export function fetchWithAWSAuth(input: RequestInfo, metricsPublisher: KatalMetricsPublisher, authSession: AuthSession|null, token: string, region: string, init?: RequestInit) {
  if (authSession && authSession.credentials) {
    if (!init) {
      init = {}
    }
    const parsedUrl = new URL(window.location.href);
    let url;
    if(typeof input === 'string') {
      url = input as string;
    } else {
      url = (input as Request).url;
    }
    // The URL constructor accepts either an absolute or relative URL in its first parameter.
    // If the URL is absolute, the second parameter will be ignored. Otherwise, a URL will be
    // constructed using the second parameter as a base URL and using the first parameter as
    // a relative path from the base.
    const fetchUrl = new URL(url, parsedUrl.origin);
    const requestHeaders = {
      'AuthenticationToken': token,
      ...init.headers
    };
    const requestOptions = {
      host: fetchUrl.host,
      path: fetchUrl.pathname,
      uri: fetchUrl.href,
      protocol: fetchUrl.protocol,
      headers: requestHeaders,
      body: init.body,
      method: init.method,
      service: SERVICE_NAME,
      region
    };

    aws4.sign(requestOptions, {
      secretAccessKey: authSession!.credentials.credentials.secretAccessKey,
      accessKeyId: authSession!.credentials.credentials.accessKeyId,
      sessionToken: authSession!.credentials.credentials.sessionToken
    });
    return fetchAPI(input, requestOptions, metricsPublisher);
  } else {
    return Promise.reject('AuthManager not enabled or credentials are missing.');
  }
}

function fetchAPI(input: RequestInfo, requestOptions: any, metricsPublisher: KatalMetricsPublisher) {
  const fetchStartTime = performance.now();
  return fetch(input, requestOptions).then((response) => {
    metricsPublisher
      .newChildActionPublisherForMethod('FetchTimeForGraphQl')
      .publishCounterMonitor(
        'FetchTimeForGraphQl',
        performance.now() - fetchStartTime
      );
    return response;
  })
}